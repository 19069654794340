export function dataTableInit(wrapper, callback = () => {}) {
    // After datatable inited.
    const int = setInterval(function () {
        const $wrapper = jQuery(wrapper);

        if ($wrapper.length) {
            tableHeaderInit($wrapper);
            tableFooterInit($wrapper);

            callback($wrapper);
            clearInterval(int);
        }
    }, 700);

    setTimeout(function () {clearInterval(int)}, 10000);
}

window.dataTableInit = dataTableInit;

function tableHeaderInit(wrapper) {
    /** @global */
    const $wrapper = jQuery(wrapper);

    function moveFilterToHeader() {
        const $filter = $wrapper.find('.dataTables_filter');
        // Append search icon.
        $filter.append('<i class="ml-3 fas fa-search"></i>');
        $filter.appendTo($wrapper.find('.table-header'));
    }

    function appendSortIcons() {
        $wrapper.find('th.sorting').each((i, el) => {
            $(el).append('<span class="sort"></span>');
        });
    }

    moveFilterToHeader();
    appendSortIcons();
}

function tableFooterInit(wrapper) {
    const $wrapper = jQuery(wrapper);

    // Create footer.
    const $footer = jQuery('<div class="table-footer"></div>');
    $wrapper.append($footer);

    // Move elements to footer.
    $wrapper.find('.dataTables_length').appendTo($footer);
    $wrapper.find('.dataTables_info').appendTo($footer);
    $wrapper.find('.dataTables_paginate').appendTo($footer);
}
